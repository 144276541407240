import moment from "moment";
import writeXlsxFile from "write-excel-file";

export default {
    methods: {
        async exportToExcel(columnNames, data, fileName = "exportFile.xlsx") {
            let schema = this.getTableSchema(columnNames);
            await writeXlsxFile(data, {
                schema,
                fileName: fileName
            });
        },
      /*  async exportSchemaToExcel(data, schema, fileName = "exportFile.xlsx") {
            await writeXlsxFile(data, {
                schema,
                fileName: fileName
            });
        },*/
        getTableSchema(columnNames) {
            const schema = [];
            for (var i = 0; i < columnNames.length; i++) {
                let columnName = columnNames[i].text;
                let columnValue = columnNames[i].value;
                schema.push({
                    column: columnName,
                    value: item => {
                        if (columnValue === 'contract_expiration_date' && item[columnValue] === null) return 'Indefinitely'
                        else if (columnValue === 'total_cost') return item[columnValue].toFixed(2)
                        else if (columnValue === 'productivity_report.for_month') return this.$moment(item.productivity_report.for_month).format('MMMM YYYY')
                        else if (columnValue === 'user.name') return item.user.name
                        else return item[columnValue]
                    }
                });
            }
            return schema;
        },

    }
};
